<template>
  <div class="itemList">
    <template v-if="showOffers">
      <offer-list></offer-list>
    </template>
    <SimpleBannerVue
      v-if="collectionBannerData.length"
      :banner-data="collectionBannerData"
    />
    <div
      id="headerDescription"
      v-if="
        !isSearching &&
        (this.itemType &&
        this.itemType !== '' ||
        this.$route.name === 'category') &&
        this.itemType !== 'shop' &&
        this.$route.name !== 'HomePage' &&
        typeof this.$route.query.q === 'undefined' &&
        typeof this.$route.query.geo === 'undefined'
      "
      class="itemList__header"
    >
      <h1 v-if="this.$route.name === 'tag'" class="title">#{{ $route.params.tag }}</h1>
      <h1 v-else-if="collectionTitle" class="title">{{ collectionTitle }}</h1>
      <h1 v-else-if="propsCollectionTitle" class="title">{{ propsCollectionTitle }}</h1>
      <h1 v-else-if="this.itemType" class="title">
        {{ $ml.get(this.itemType + '.title') }}
      </h1>
    </div>
    <div
      v-else-if="isSearching"
      class="itemList__header"
    >
    <h1 class="title">Search</h1>
    </div>
    <vue-markdown
      :key="collectionData.description"
      v-if="!isSearching
        && showDescription
        && collectionData
        && collectionData.description
        && collectionData.description.length > 0"
      class="description"
    >{{ collectionData.description }}</vue-markdown>
    <div
      v-if="!isSearching"
      class="childCategories"
    >
      <router-link
        v-for="category in childCollections"
        :key="category.slug"
        :to="category.slug"
        class="childCategory"
      >
        {{ category.name }}
      </router-link>
    </div>
    <div class="menuIconContainer">
      <!-- Container for dropdown of Allergens -->
      <div v-if="isProductList" class="dropdown">
        <button
          class="btn-dropdown"
          @click.prevent.stop="handleDropdown()"
        >
          <span style="display: flex">
            {{ 'Allergens' }}
            <img
              src="@/assets/angle-down.svg"
              alt="dropdown arrow"
              style="height: 17px; width: 17px; padding-left: 10px" />
          </span>
        </button>
        <div
          v-show="displayAllergensDropdown"
          class="dropdown-content"
        >
          <div class="dropdown-col">
            <button
              v-for="(allergen, index) in allergenFilters"
              :key="allergen.value+index"
              :class="{ 'dropdown-button-selected': allergen.selected }"
              @click="allergen.selected = !allergen.selected"
            >
              <!-- <font-awesome-icon icon="fa-regular fa-square" style="font-size: 16px"/> -->
              <i
                v-if="!allergen.selected"
                class="fal fa-square"
              />
              <i
                v-else-if="allergen.selected"
                class="fal fa-check-square"
              />
              {{ allergen.name }}
            </button>
          </div>
        </div>
        <div
          v-show="displayAllergensDropdown"
          class="background-close-dropdown"
          @click="handleDropdown(false)"
        ></div>
      </div>
      <div v-if="isProductList" class="dropdown">
        <button
          class="btn-dropdown"
          @click.prevent.stop="handleSortDropdown()"
        >
          <span style="display: flex">
            {{ 'Sort By' }}
            <img
              src="@/assets/angle-down.svg"
              alt="dropdown arrow"
              style="height: 17px; width: 17px; padding-left: 10px" />
          </span>
        </button>
        <div v-show="displaySortByDropdown" class="dropdown-content">
          <div class="drodown-col">
            <button
              v-for="order in sortByOptions"
              :key="order.value"
              :class="{'dropdown-button-selected': sortBy === order.value}"
              @click="sortBy = order.value"
            >
              {{order.name}}
            </button>
          </div>
        </div>
         <div
          v-show="displaySortByDropdown"
          class="background-close-dropdown"
          @click="handleSortDropdown(false)"
        ></div>
      </div>
      <button
        @click="displayHorizontal = true"
        class="menuIconButton"
      >
        <font-awesome-icon class="menuIcon" icon="fa-solid fa-list" />
      </button>
      <button
        @click="displayHorizontal = false"
        class="menuIconButton"
      >
        <font-awesome-icon class="menuIcon" icon="fa-solid fa-grip" />
      </button>
    </div>
    <Listing
      v-if="!isArticleList && sortBy === 'default'"
      :displayModal="true"
      :query="$store.state.filteringQuery"
      :collection="isSearching ? null : productCollection"
      :collectionArray="isSearching ? null : subCollectionsArray"
      :geo="$store.state.filteringGeo"
      :tag="this.$route.name === 'tag' ? this.$route.params.tag : null"
      :itemType="this.itemType"
      :horizontal="displayHorizontal"
      :userLogged="$store.getters.userLoggedIn"
      :extraFilters="formattedFilters"
      :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
      :disabledCollections="['vk_icecream']"
      @addItemToCart="$emit('addItemToCart', $event)"
      @openSnackbar="$emit('openSnackbar', $event)"
    />
    <ais-instant-search
      v-else-if="!isArticleList"
      :search-client="algoliaClient"
      :index-name="algoliaSortByNewestIndex"
    >
      <Listing
        :displayModal="true"
        :query="$store.state.filteringQuery"
        :collection="isSearching ? null : productCollection"
        :collectionArray="isSearching ? null : subCollectionsArray"
        :geo="$store.state.filteringGeo"
        :tag="this.$route.name === 'tag' ? this.$route.params.tag : null"
        :itemType="this.itemType"
        :horizontal="displayHorizontal"
        :userLogged="$store.getters.userLoggedIn"
        :extraFilters="formattedFilters"
        :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
        :disabledCollections="['vk_icecream']"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
    </ais-instant-search>
    <ais-instant-search
      v-else
      :search-client="algoliaClient"
      :index-name="algoliaSortByNewestIndex"
    >
      <Listing
        :displayModal="true"
        :query="$store.state.filteringQuery"
        :collection="isSearching ? null : productCollection"
        :collectionArray="isSearching ? null : subCollectionsArray"
        :geo="$store.state.filteringGeo"
        :tag="this.$route.name === 'tag' ? this.$route.params.tag : null"
        :itemType="this.itemType"
        :horizontal="displayHorizontal"
        :ignoreVeganFlag="true"
        :userLogged="$store.getters.userLoggedIn"
        :extraFilters="formattedFilters"
        :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
        :disabledCollections="['vk_icecream']"
        @addItemToCart="$emit('addItemToCart', $event)"
        @openSnackbar="$emit('openSnackbar', $event)"
      />
    </ais-instant-search>
  </div>
</template>

<script>
import metatagsData from '@/metatagsData.js'
// import categoriesCollections from '@/categoriesCollections.js'
import httpClient from '@/services/httpClient'
import { createWidgetMixin } from 'vue-instantsearch'
import { connectInfiniteHits } from 'instantsearch.js/es/connectors'
import siteConstants from '@/const.js'
import VueMarkdown from 'vue-markdown'
import { algoliaClient } from '@kiukicom/listing'

export default {
  name: 'ItemList',
  components: {
    VueMarkdown,
    SimpleBannerVue: () => import('@/components/SimpleBanner.vue'),
    OfferList: () => import('@/components/Offers/OfferList.vue')
  },
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  props: {
    showCollectionName: {
      required: false,
      type: Boolean,
      default: false
    },
    showDescription: {
      required: false,
      type: Boolean,
      default: true
    },
    itemType: {
      required: false,
      type: String
    },
    action: {
      required: false,
      type: String
    },
    // CollectionName is the name of the collection to fetch from algoliai
    collectionName: {
      required: false,
      type: String
    },
    propsCollectionTitle: {
      required: false,
      type: String
    }
  },
  data: function () {
    return {
      algoliaClient,
      algoliaIndex: process.env.VUE_APP_ALGOLIA_INDEX,
      siteConstants,
      cards: [],
      collectionData: {},
      loadingComplete: false,
      showOffers: false,
      metatagsData: metatagsData,
      notFoundMessage: '',
      loadingCollections: false,
      displayHorizontal: true,
      collectionBannerData: [],
      allergenFilters: [
        {
          name: 'Gluten Free',
          value: 'gluten_free',
          selected: false
        },
        {
          name: 'Peanut Free',
          value: 'peanut_free',
          selected: false
        },
        {
          name: 'Nut Free',
          value: 'nut_free',
          selected: false
        },
        {
          name: 'Soy Free',
          value: 'soy_free',
          selected: false
        }
      ],
      sortBy: 'default',
      sortByOptions: [
        {
          name: 'Default',
          value: 'default'
        },
        {
          name: 'Newest',
          value: 'new_to_old'
        // },
        // {
        //   name: 'Oldest',
        //   value: 'old_to_new'
        }
      ],
      displaySortByDropdown: false,
      displayAllergensDropdown: false,
      // CollectionTitle => Used to display title of category on top of the listing
      collectionTitle: '',
      // PageTitle is used for metaTags Data
      pageTitle: '',
      // Slug fetched from $route.params to help find the collection and associated Data on categoriesCollections
      slug: '',
      // Collection for algolia to filter by
      productCollection: '',
      // Collections Array, used for the <Listing /> element to fetch multiple items from the current collection
      // and all of it's sub-collections at once
      subCollectionsArray: [],
      // Collection array used to populate "pill buttons" on top off the listing to better facilitate
      // category/sub-category navigation
      childCollections: []
    }
  },
  created () {
    this.computeDefaultDisplay()
    // Get prop's "collectionName" into local var to avoid warnings/issues/errors
    this.productCollection = this.collectionName

    if (this.itemType === 'article') {
      // If the ItemType is 'articles', nothing else needs to be done => Fetch collection provided
      // by props.collectionName
      return
    }
    // When mounted and if the (prop) this.productCollection is empty, get url
    // slug and match it to the corresponding collection (vk_{something})
    if (this.productCollection === '' && this.$route.path !== '/online-shopping') {
      this.slug = this.$route.params.slug
      if (this.slug) {
        // Find corresponding route/slug/collection
        this.findCollectionBySlug()
      }
    } else if (
      this.collectionName !== undefined &&
      this.collectionName !== '' &&
      this.$route.path !== '/online-shopping') {
      this.findCollectionByCollection()
    }
    // Check if we're actually filtering by a collection, otherwise this call would be pointless
    if (this.productCollection) {
      this.getCollectionData(this.productCollection)
    }
  },
  methods: {
    handleDropdown (newVal = null) {
      if (this.displaySortByDropdown) this.displaySortByDropdown = false
      if (newVal === null) {
        this.displayAllergensDropdown = !this.displayAllergensDropdown
      } else {
        this.displayAllergensDropdown = newVal
      }
    },
    handleSortDropdown (newVal = null) {
      if (this.displayAllergensDropdown) this.displayAllergensDropdown = false
      if (newVal === null) {
        this.displaySortByDropdown = !this.displaySortByDropdown
      } else {
        this.displaySortByDropdown = newVal
      }
    },
    computeDefaultDisplay () {
      // Make Grid display default for shopping or and for recipes
      if (
        this.userData.attrs !== undefined &&
        this.userData.attrs !== null &&
        this.userData.attrs.layout !== null &&
        this.userData.attrs.layout !== undefined
      ) {
        this.displayHorizontal = this.userData.attrs.layout === 'list'
        return
      }
      if (
        this.$route.path.includes('/online-shopping') ||
        this.$route.path.startsWith('/recipes') ||
        this.$route.path.startsWith('/mag') ||
        this.$route.params.slug
      ) {
        this.displayHorizontal = false
      }
    },
    getChildCategories (category) {
      // Get the first level of child categories, with all the available fields to
      // populate buttons on the top of the page to better link sub-categories
      let collectionArray = []

      if (category.subCategories) {
        category.subCategories.forEach(subCat => {
          // save only name and slug, since sub-collections / collection isn't
          // relevant for this object
          collectionArray.push({
            name: subCat.name,
            slug: subCat.slug
          })
        })
      }
      return collectionArray
    },
    getSubCategoriesArray (category) {
      // Get all categories that are under the selected Category
      // This method should be allowed to be called recursively
      let collectionArray = []
      // If it has sub-categories, iterate through each one, and add them to the array
      if (category.subCategories) {
        category.subCategories.forEach(subCat => {
          // * Verify if collection is defined, is not null and has field properly populated
          if (
            subCat.collection !== undefined &&
            subCat.collection !== null &&
            subCat.collection.length > 1) {
            collectionArray.push(subCat.collection)
          }
          if (subCat.subCategories) {
            collectionArray = [...collectionArray, ...this.getSubCategoriesArray(subCat)]
          }
        })
      }
      return collectionArray
    },
    getCollectionData (collectionId) {
      // Fetches collection data from our backend, to populate "Description" Field, and whatever else
      // may be needed
      httpClient
        .get(`item-collection/${collectionId}`)
        .then(response => {
          this.collectionData = response.data
          for (let picture of response.data.pictures) {
            this.collectionBannerData = [...this.collectionBannerData, { title: '', src: picture }]
          }
          this.$forceUpdate()
        })
    },
    findCollectionBySlug () {
      let collection = null
      let collectionTitle = null
      if (this.categoriesCollections.length === 0) {
        return
      }
      // Iterate 1st level of categoryCollections
      this.categoriesCollections.forEach(el => {
        if (collection) { return } // If a match has been found already, exit loop
        if (el.subCategories) {
          // Search sub-categories
          el.subCategories.forEach(el2 => {
            if (collection) { return } // If a match has been found already, exit loop
            if (el2.subCategories) {
              // Search sub-sub-categories
              el2.subCategories.forEach(el3 => {
                if (collection) { return } // If a match has been found already, exit loop
                if (el3.subCategories) {
                  // Search sub-sub-categories
                  el3.subCategories.forEach(el4 => {
                    if (collection) { return } // If a match has been found already, exit loop
                    // There are no 5th level categories
                    if (el4.slug === this.slug) {
                      collection = el4.collection
                      collectionTitle = el4.name
                      this.subCollectionsArray = this.getSubCategoriesArray(el4)
                      this.childCollections = this.getChildCategories(el4)
                    }
                  })
                }
                // There are no 4th level categories
                if (el3.slug === this.slug) {
                  collection = el3.collection
                  collectionTitle = el3.name
                  this.subCollectionsArray = this.getSubCategoriesArray(el3)
                  this.childCollections = this.getChildCategories(el3)
                }
              })
            }
            if (el2.slug === this.slug) {
              collection = el2.collection
              collectionTitle = el2.name
              this.subCollectionsArray = this.getSubCategoriesArray(el2)
              this.childCollections = this.getChildCategories(el2)
            }
          })
        }
        if (el.slug === this.slug) {
          // Updates "collection" var value
          collection = el.collection
          collectionTitle = el.name
          this.subCollectionsArray = this.getSubCategoriesArray(el)
          this.childCollections = this.getChildCategories(el)
        }
      })
      this.collectionTitle = collectionTitle
      this.productCollection = collection
      if (collectionTitle === null && collection === null) {
        // this.$router.replace({ name: '404' })
        this.collectionTitle = ''
        this.$store.commit('updateNotFound')
      }
    },
    findCollectionByCollection () {
      let collection = null
      let collectionTitle = null
      // Iterate 1st level of categoryCollections
      this.categoriesCollections.forEach(el => {
        if (collection) { return } // If a match has been found already, exit loop
        if (el.subCategories) {
          // Search sub-categories
          el.subCategories.forEach(el2 => {
            if (collection) { return } // If a match has been found already, exit loop
            if (el2.subCategories) {
              // Search sub-sub-categories
              el2.subCategories.forEach(el3 => {
                if (collection) { return } // If a match has been found already, exit loop
                if (el3.subCategories) {
                  // Search sub-sub-categories
                  el3.subCategories.forEach(el4 => {
                    if (collection) { return } // If a match has been found already, exit loop
                    if (el4.collection === this.collectionName) {
                      console.log('here')
                      collection = el4.collection
                      collectionTitle = el4.name
                      this.subCollectionsArray = this.getSubCategoriesArray(el4)
                      this.childCollections = this.getChildCategories(el4)
                    }
                  })
                }
                if (el3.collection === this.collectionName) {
                  collection = el3.collection
                  collectionTitle = el3.name
                  this.subCollectionsArray = this.getSubCategoriesArray(el3)
                  this.childCollections = this.getChildCategories(el3)
                }
              })
            }
            if (el2.collection === this.collectionName) {
              collection = el2.collection
              collectionTitle = el2.name
              this.subCollectionsArray = this.getSubCategoriesArray(el2)
              this.childCollections = this.getChildCategories(el2)
            }
          })
        }
        if (el.collection === this.collectionName) {
          // Updates "collection" var value
          collection = el.collection
          collectionTitle = el.name
          this.subCollectionsArray = this.getSubCategoriesArray(el)
          this.childCollections = this.getChildCategories(el)
        }
      })
      this.collectionTitle = collectionTitle
      this.productCollection = collection
      if (collectionTitle === null && collection === null) {
        // this.$router.replace({ name: '404' })
        this.$store.commit('updateNotFound')
      }
    }
  },
  mounted () {

  },
  watch: {
    categoriesCollections () {
      if (this.productCollection === '' && this.$route.path !== '/online-shopping') {
        this.slug = this.$route.params.slug
        if (this.slug) {
          // Find corresponding route/slug/collection
          this.findCollectionBySlug()
        }
      } else if (
        this.collectionName !== undefined &&
        this.collectionName !== '' &&
        this.$route.path !== '/online-shopping') {
        this.findCollectionByCollection()
      }
      // Check if we're actually filtering by a collection, otherwise this call would be pointless
      if (this.productCollection) {
        this.getCollectionData(this.productCollection)
      }
    },
    userData () {
      this.computeDefaultDisplay()
    },
    collectionTitle: {
      handler (newValue) {
        if (newValue.includes(String.fromCodePoint(0x1f384)) && !newValue.includes('Eats & Treats')) {
          this.collectionTitle = `${this.collectionTitle} Eats & Treats`
        }
      }
    },
    $route (to) {
      if (to.query.query === undefined) {
        this.pageTitle = 'Search'
      } else {
        this.pageTitle = to.query.query.replace('+', ' ')
      }
    },
    // Check filters to send to listing
    allergenFilters: {
      handler (newValue) {
        // console.log(newValue[0].selected, newValue[1].selected, newValue[2].selected)
      },
      deep: true
    }
  },
  metaInfo () {
    const type = this.itemType || 'homepage'
    if (this.metatagsData[this.$ml.current][type].description === '') {
      return {
        title:
          this.metatagsData[this.$ml.current][type].name ||
          '#' + this.$route.params.tag
      }
    }

    const queryParams = new URLSearchParams(window.location.search)
    const query = queryParams.get('query')
    this.pageTitle = query !== null
      ? query.replace('+', ' ')
      : 'Search'

    return {
      title:
        `${
          this.collectionTitle.length
            ? this.collectionTitle
            : window.location.pathname.includes('/search')
              ? this.pageTitle
              : this.metatagsData[this.$ml.current][type].name
        }` ||
        '#' + this.$route.params.tag,
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.metatagsData[this.$ml.current][type].tags
        },
        {
          vmid: 'description',
          name: 'description',
          content:
            this.metatagsData[this.$ml.current][type].description
        },
        // OpenGraph data
        {
          property: 'og:title',
          content: `${
            this.collectionTitle.length
              ? this.collectionTitle + ` - ${siteConstants.siteName}`
              : window.location.pathname.includes('/search')
                ? this.pageTitle
                : this.metatagsData[this.$ml.current][type].name
          }`
        },
        { property: 'og:site_name', content: `${siteConstants.siteName}` },
        // The list of types is available here: http://ogp.me/#types
        { property: 'og:type', content: 'website' },
        // Should the the same as your canonical link, see below.
        {
          property: 'og:image',
          content:
            'https://thevegankind.com/images/TVKS_preview_X2.jpg'
            // this.metatagsData[this.$ml.current][type].pictures[0]
        },
        // Often the same as your meta description, but not always.
        {
          property: 'og:description',
          content:
            this.metatagsData[this.$ml.current][type].description
        },

        // Twitter card
        { name: 'twitter:card', content: 'summary_large_image' },
        {
          name: 'twitter:site',
          content: `${siteConstants.siteUrl}/${type}`
        },
        {
          name: 'twitter:title',
          content: `${
            this.metatagsData[this.$ml.current][type].name
          } - ${siteConstants.siteName}`
        },
        {
          name: 'twitter:description',
          content:
            this.metatagsData[this.$ml.current][type].description
        },
        // Your twitter handle, if you have one.
        {
          name: 'twitter:image:src',
          content:
            this.metatagsData[this.$ml.current][type].pictures[0]
        },

        // Google / Schema.org markup:
        {
          itemprop: 'name',
          content: `${
            this.metatagsData[this.$ml.current][type].name
          } - ${siteConstants.siteName}`
        },
        {
          itemprop: 'description',
          content:
            this.metatagsData[this.$ml.current][type].description
        },
        {
          itemprop: 'image',
          content:
            this.metatagsData[this.$ml.current][type].pictures[0]
        }
      ]
    }
  },
  computed: {
    categoriesCollections () {
      return this.$store.state.serverFetchedCategories
    },
    userData () {
      return this.$store.state.userData
    },
    computedIndex () {
      switch (this.sortBy) {
        case 'new_to_old':
          return `${this.algoliaIndex}_new_to_old`
        case 'old_to_new':
          return `${this.algoliaIndex}_old_to_new`
        default:
          return `${this.algoliaIndex}`
      }
    },
    isSearching () {
      return this.$store.state.filteringQuery.length > 0
    },
    isProductList () {
      const route = this.$route.path
      return (route.includes('/c/') || route.includes('/search') || route.includes('/online-shopping'))
    },
    isArticleList () {
      // ? Checks for route to know if it's on recipes list or articles list.
      // ? * /mag (magazine articles list)
      // ? * /recipes (recipes list)
      // ? In the future might make sense to check if the route is not actually "inside" an article
      // ? since this could impact the list ordering on the _related articles_ list.
      const route = this.$route.path
      return (route.includes('/mag') || route.includes('/recipes'))
    },
    algoliaSortByNewestIndex () {
      // ! Make sure the naming scheme on Algolia is consistent
      return `${this.algoliaIndex}_new_to_old`
    },
    collectionNames () {
      return this.$store.state.collectionNames
    },
    formattedFilters () {
      const filterArray = []
      this.allergenFilters.forEach(allergen => {
        if (allergen.selected) {
          filterArray.push(`${allergen.value}:true`)
        }
      })
      if (this.isArticleList) {
        filterArray.push('approval:approved')
      }
      return filterArray
    }
  }
}
</script>

<style scoped lang="stylus">
.itemList {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  padding: 0 10px;

  .childCategories {
    display: flex;
    flex-wrap: wrap;

    .childCategory {
      margin: 5px 10px 5px 0px;
      background-color: #ddd7e7;
      color: #000;
      padding: 5px 10px;
      border-radius: 50px;
      cursor: pointer;
      transition: 0.25s all ease

      &:hover {
        background-color: #AEABB7;
      }
    }
  }

  .menuIconContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    .menuIconButton {
      cursor: pointer;
      background: #fff;
      border: none;
      padding: 5px;
      margin: 0 5px 0 5px;
      color: #000;

      .menuIcon {
        // padding: 5px 5px 5px 5px;
        font-size: 16px;
      }
    }

    .dropdown {
      position: relative;
      display: inline-block;

      .background-close-dropdown {
        width: 100%;
        height: 100vh;
        z-index: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: fixed
      }

      .btn-dropdown {
        border-radius: 5px;
        // border: solid 1px #b3b3b3;
        border: none;
        padding: 5px 10px;
        margin-right: 10px;
        cursor: pointer;
        font-size: 15px;
        font-family: Helvetica;
        background-color: white;
      }

      .dropdown-content {
        position: absolute;
        width: max-content;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        background-color: #FFFFFF;
        border-radius: 5px;
        z-index: 5;

        button {
          color: black;
          padding: 10px 14px;
          text-decoration: none;
          display: block;
          background-color: #FFFFFF;
          border: none;
          cursor: pointer;
          width: 100%;
          text-align: left;
          font-size: 15px;

          i {
            margin-right: 5px;
            font-weight: 800
          }
        }

        button:hover {
          background-color: #EEEEEE;
        }

        .dropdown-button-selected {
          background-color: #f0f0f0 !important;
        }

        .dropdown-col {
          width: 200px;
        }
      }
    }
  }

  .description {
    text-align: left;
    opacity: .7;
    margin-bottom: 15px;
  }

  &__header {
    border-radius: 6px;
    margin: 10px 0 0px 0;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between

    .title {
      font-family: 'Bebas Neue', sans-serif;
    }
  }
  //? Careful here
  /deep/.itemCard__title {
    font-family: 'Helvetica Regular', sans-serif
  }
}

.not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: left;

  &__left {
    float: left;
    margin: 15px 15px 0 0;
  }

  &__right {
    float: right;
    padding: 2% 0;
  }
}
</style>
