export default {
  english: {
    homepage: {
      name: 'Wholefoods - Vegan Kind',
      description: `Enjoy exceptional vegan experiences in amazing places around the world.
      Start your vegan journey with us and discover that #veganisbetter.`,
      tags: 'vegan guide,vegan restaurants,vegan hotels,vegan holidays',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    search: {
      name: 'Wholefoods',
      description: `Enjoy exceptional vegan experiences in amazing places around the world.
      Start your vegan journey with us and discover that #veganisbetter.`,
      tags: 'vegan guide,vegan restaurants,vegan hotels,vegan holidays',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    packagedfood: {
      name: 'Online shopping',
      description: 'Online shopping',
      tags: 'online shopping,online shopping near me',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    restaurant: {
      name: 'Vegan restaurants',
      description: 'Healthy and delicious food that is better for you and for the environment. Join the food culture revolution and connect to the places that were designed to deliver the best vegan cuisine.',
      tags: 'vegan restaurants,vegan restaurants near me',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    hotel: {
      name: 'Vegan hotels',
      description: `Get to know the hotels that combine quality with cruelty-free philosophy,
      healthy food with local cultures, and sustainability efforts to save the planet.`,
      tags: 'vegan hotels,vegan friendly hotels',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    cafe: {
      name: 'Vegan cafes',
      description: `Experience vegan city life and its cosy cafes.
      Expect good coffee and tea, natural drinks and smoothies, plant-based snacks, delicious vegan organic cakes and some friendly casual atmosphere.
      Discover vegan corner spots designed for a quick escape from the daily rush.`,
      tags: 'vegan cafes,vegan cafes near me',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    article: {
      name: 'Mag',
      description: `Discover articles on vegan lifestyle, travelling and dining.
        Expect cruelty-free writing, interesting stories and sustainable ideas.`,
      tags: 'vegan articles, vegan magazine, vegan blog',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    offer: {
      name: 'Wholefoods',
      description: `Enjoy exceptional vegan experiences in amazing places around the world.
      Start your vegan journey with us and discover that #veganisbetter.`,
      tags: 'vegan guide,vegan restaurants,vegan hotels,vegan holidays',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    tag: {
      name: 'Wholefoods',
      description: `Enjoy exceptional vegan experiences in amazing places around the world.
      Start your vegan journey with us and discover that #veganisbetter.`,
      tags: 'vegan guide,vegan restaurants,vegan hotels,vegan holidays',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    recipe: {
      name: 'Plant-based Recipes',
      description: ``,
      tags: '',
      pictures: ['']
    }
  },
  polish: {
    homepage: {
      name: 'Najlepsze wegańskie miejsca i atrakcje',
      description: `Odkryj wyjatkowe weganske atrakcje w niesamowitych miejscach na swiecie. 
      Rozpocznij wegańska przygodę z nami i odkryj, że wegańskie, znaczy lepsze.`,
      tags: 'poradnik wegański,wegańskie restauracje,wegańskie hotele,wegańskie wakacje',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    packagedfood: {
      name: 'Online shopping',
      description: 'Online shopping',
      tags: 'online shopping,online shopping near me',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    restaurant: {
      name: 'Wegańskie restauracje',
      description: 'Zdrowe i pyszne jedzenie, które jest lepsze dla Ciebie i środowiska. Dołącz do rewolucji żywieniowej i wybróbuj miejsca, które zostały stworzone z myślą o najlepszej wegańskiej kuchni.',
      tags: 'wegańskie restauracje,wegańskie restauracje w pobliżu',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    hotel: {
      name: 'Wegańskie hotele',
      description: `Poznaj hotele, które łączą wysoką jakość usług, zdrowe jedzenie i lokalną kulturę
        bez okrucieństwa dla zwierząt.`,
      tags: 'wegańskie hotele,hotele przyjazne weganom',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    cafe: {
      name: 'Wegańskie kawiarnie',
      description: `Odkryj kawiarnie, które oferują najlepszą kawę w mieście.`,
      tags: 'wegańskie kawiarnie,wegańskie kawiarnie w pobliżu',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    article: {
      name: 'Magazyn',
      description: 'Odkrywaj artykuły o wegańskim stylu życia. Czytaj o zdrowym żywieniu, gastronomii i podróżach. Publikujemy ciekawe teksty o zrównoważonym życiu bez okrucieństwa.',
      tags: 'wegański magazyn, wegańskie artykuły, gazeta wegańska, blog vegański',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    offer: {
      name: 'Magazyn',
      description: 'Odkrywaj artykuły o wegańskim stylu życia. Czytaj o zdrowym żywieniu, gastronomii i podróżach. Publikujemy ciekawe teksty o zrównoważonym życiu bez okrucieństwa.',
      tags: 'wegański magazyn, wegańskie artykuły, gazeta wegańska, blog vegański',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    },
    tag: {
      name: 'Crafting the best vegan experiences',
      description: `Enjoy exceptional vegan experiences in amazing places around the world.
      Start your vegan journey with us and discover that #veganisbetter.`,
      tags: 'vegan guide,vegan restaurants,vegan hotels,vegan holidays',
      pictures: ['https://thevegankind.com/images/TVKS_preview_X2.jpg']
    }
  }
}
